import React from 'react'
import '../../../App.css'

export default function ClientChecker(){
    return (
        <div className='container'>
            <div>
                <h1 className='about-me'>Client Checker</h1>
                <div className='smallcard'>This project is best described on <a href="https://github.com/lperniciaro/Client-Checker">Github</a>
                </div>
                
            </div>
        </div>
    )
}