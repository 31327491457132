import React from 'react'
import '../../App.css'
import Cards from '../Cards';

export default function Portfolio() {
    return (
        <>
        <Cards />
        </>
    )
}